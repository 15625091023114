<template>
  <h1>Client and Setter Data</h1>
  <div class="card">
    <div class="iframe-container">
      <iframe width="100%" height="100%" src="https://lookerstudio.google.com/embed/reporting/9f2aa8f5-b0a0-4ade-b708-058a765573e9/page/n9C5D" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
    </div>
  </div>
</template>

<style scoped>
h1 {
  margin-top: 0;
  text-align: left;
}
.card {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  padding-top: 0;
}
.iframe-container {
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  height: 1400px;
  width: 100%;
  padding-top: 75%;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>