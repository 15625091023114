import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    authenticated: false,
    user: null,
    role: null
  },
  mutations: {
    SET_AUTHENTICATED(state, authenticated) {
      state.authenticated = authenticated
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_ROLE(state, role) {
      state.role = role
    }
  },
  actions: {
    login({ commit }, user) {
      commit('SET_AUTHENTICATED', true)
      commit('SET_USER', user)
      commit('SET_ROLE', user.role)
    },
    logout({ commit }) {
      commit('SET_AUTHENTICATED', false)
      commit('SET_USER', null)
      commit('SET_ROLE', null)
    },
    async checkAuth({ commit }) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_AUTH_URL}/status`, {
          withCredentials: true
        })
        if (response.data.authenticated) {
          commit('SET_AUTHENTICATED', true)
          commit('SET_USER', response.data.user)
          commit('SET_ROLE', response.data.user.role)
        } else {
          commit('SET_AUTHENTICATED', false)
          commit('SET_USER', null)
          commit('SET_ROLE', null)
        }
      } catch (error) {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
        commit('SET_ROLE', null)
      }
    }
  }
})