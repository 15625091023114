import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/aura-light-green/theme.css'
import 'primeicons/primeicons.css'
import './assets/base.css'
import store from './auth/store'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import Message from 'primevue/message'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Chart from 'primevue/chart'
import Tag from 'primevue/tag'
import TriStateCheckbox from 'primevue/tristatecheckbox'
import Calendar from 'primevue/calendar'
import FloatLabel from 'primevue/floatlabel'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Menu from 'primevue/menu'
import ProgressSpinner from 'primevue/progressspinner'
import InputNumber from 'primevue/inputnumber'
import RadioButton from 'primevue/radiobutton'
import Listbox from 'primevue/listbox'
import ToggleButton from 'primevue/togglebutton'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'

const app = createApp(App)
app.use(router).use(PrimeVue).use(ToastService).use(ConfirmationService).use(store).mount('#app')

app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('InputText', InputText)
app.component('Button', Button)
app.component('Dialog', Dialog)
app.component('Dropdown', Dropdown)
app.component('Message', Message)
app.component('Toast', Toast)
app.component('Chart', Chart)
app.component('Tag', Tag)
app.component('TriStateCheckbox', TriStateCheckbox)
app.component('Calendar', Calendar)
app.component('FloatLabel', FloatLabel)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Menu', Menu)
app.component('ProgressSpinner', ProgressSpinner)
app.component('InputNumber', InputNumber)
app.component('RadioButton', RadioButton)
app.component('Listbox', Listbox)
app.component('ToggleButton', ToggleButton)
app.component('ConfirmDialog', ConfirmDialog)