<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()

const store = useStore()
const googleAuthUrl = ref(`${process.env.VUE_APP_API_AUTH_URL}/google`)
const notFound = ref(false)

const checkAuth = async () => {
  if (store.state.authenticated) {
    router.push({ name: 'home' }) // Redirect to home if authenticated
  }
}

onMounted(async () => {
  checkAuth()
  await router.isReady()
  notFound.value = route.query.found
})
</script>

<template>
  <div class="login">
    <div class="login-container">
      <img src="../assets/images/logo.svg" alt="Logo" class="logo">
      <div class="cta">
        <a :href="googleAuthUrl">Sign in with Google</a>
      </div>
      <p v-if="notFound" class="not-found">User was not found. Contact administration to create an account.</p>
    </div>
  </div>
</template>

<style scoped>
.login {
  display: grid;
  place-items: center;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  color: #fff;
  background-image: url('../assets/images/Background.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.login-container {

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 90%;
  max-width: 600px;
}
.cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  padding: 1rem 1.5rem;
  border: 2px solid #fff;
  border-radius: 500px;
  transition: 0.5s;
  cursor: pointer;
  z-index: 10;
}
.cta:hover {
  border: 2px solid #000;
  background-color: #000;
}
a {
  color: white;
  text-decoration: none;
}
img.logo {
  width: 100%;
  height: auto;
  z-index: 10;
}
.not-found {
  color: red;
  z-index: 10;
}
</style>