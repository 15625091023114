<script setup>
import SendReportDialog from '@/components/SendReportDialog.vue'
import { useToast } from 'primevue/usetoast'
import { ref } from 'vue'

const toast = useToast()
const sendReportModal = ref(false)

const showReportModal = () => {
  sendReportModal.value = !sendReportModal.value
}

// Error returned from modal
const errorModal = (error) => {
  toast.add({ severity: 'error', summary: 'Error', detail: error, life: 3000 })
}

// Successfully added user
const successModal = (msg) => {
  toast.add({ severity: 'success', summary: 'Success', detail: msg, life: 3000 })
}

</script>

<template>
  <Toast />
  <div class="header">
    <h1>Client and Sales Rep Data</h1>
    <Button @click="showReportModal">Send Daily Report</Button>
  </div>
  <div class="card">
    <div class="iframe-container">
      <iframe width="100%" height="100%" src="https://lookerstudio.google.com/embed/reporting/0492f974-a6ef-4ac8-952c-c1394d7323ca/page/n9C5D" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
    </div>
  </div>
  <SendReportDialog @errorModal="errorModal" @showModal="showReportModal" @successModal="successModal" v-model:visible="sendReportModal" v-if="sendReportModal"/>
</template>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.header Button {
  height: 3rem;
}
h1 {
  margin-top: 0;
  text-align: left;
}
.card {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  padding-top: 0;
}
.iframe-container {
  position: relative;
  overflow: hidden;
  max-width: 1200px;
  height: 1400px;
  width: 100%;
  padding-top: 75%;
}
iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
