<template>
  <h2>Database Design Miro Board</h2>
  <iframe width="768" height="432" src="https://miro.com/app/live-embed/uXjVKapATNc=/?moveToViewport=-1458,-809,2410,1705&embedId=799525749271" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe>
</template>

<style scoped>
h2 {
  margin-top: 0;
  text-align: center;
}
iframe {
  width: 100%;
  min-height: 700px;
}
</style>