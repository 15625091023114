import { createRouter, createWebHistory } from 'vue-router'
import DashboardView from '../views/DashboardView.vue'
import Login from '../views/Login.vue'
import { useStore } from 'vuex'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/team',
    name: 'team',
    // route level code-splitting - lazy-loaded when the route is visited.
    component: function () {
      return import('../views/TeamView.vue')
    },
    meta: { requiresAuth: true, requiresManager: true }
  },
  {
    path: '/accounts',
    name: 'accounts',
    // route level code-splitting - lazy-loaded when the route is visited.
    component: function () {
      return import('../views/AccountsView.vue')
    },
    meta: { requiresAuth: true, requiresManager: true }
  },
  {
    path: '/team/employee-dashboard/:name',
    name: 'employee-dashboard',
    // route level code-splitting - lazy-loaded when the route is visited.
    component: function () {
      return import('../views/EmployeeDashboardView.vue')
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/team/sdr-dashboard/:name',
    name: 'sdr-dashboard',
    // route level code-splitting - lazy-loaded when the route is visited.
    component: function () {
      return import('../views/SDRDashboardView.vue')
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/team/account-dashboard/:name',
    name: 'account-dashboard',
    // route level code-splitting - lazy-loaded when the route is visited.
    component: function () {
      return import('../views/AccountDashboardView.vue')
    },
    meta: { requiresAuth: true, requiresManager: true }
  },
  {
    path: '/resources',
    name: 'resources',
    // route level code-splitting - lazy-loaded when the route is visited.
    component: function () {
      return import('../views/ResourcesView.vue')
    },
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})

// Runs before each route is accessed, checks login in state if necessary
router.beforeEach(async (to, from, next) => {
  
  const store = useStore()

  // Ensure the store is properly initialized
  if (!store.state.authenticated) {
    await store.dispatch('checkAuth')
  }

  // Get store states
  const { user, authenticated, role } = store.state

  // If route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authenticated) {
      next({ name: 'Login' }) // Redirect to login if not authenticated
    } else {
      // Admin check
      if (to.matched.some(record => record.meta.requiresAdmin) && role !== 'admin') {
        // Redirect reps and SDRs to their dashboards if attempting to access restricted pages
        if (role === 'rep') {
          if (to.name !== 'employee-dashboard' || to.params.name !== user.name) {
            next(`/team/employee-dashboard/${user.name}`) // Redirect to their own dashboard
          } else {
            next() // Allow access to their own dashboard
          }
        } else if (role === 'sdr') {
          if (to.name !== 'sdr-dashboard' || to.params.name !== user.name) {
            next(`/team/sdr-dashboard/${user.name}`) // Redirect to their own dashboard
          } else {
            next() // Allow access to their own dashboard
          }
        } else if (role === 'manager') {
          next(false) // Managers are restricted from admin-only pages
        } else {
          next(false) // Prevent further navigation for other cases
        }
      } 
      // Manager check
      else if (to.matched.some(record => record.meta.requiresManager) && role !== 'admin' && role !== 'manager') {
        if (role === 'rep') {
          next(`/team/employee-dashboard/${user.name}`) // Redirect rep to their dashboard
        } else if (role === 'sdr') {
          next(`/team/sdr-dashboard/${user.name}`) // Redirect SDR to their dashboard
        } else {
          next(false) // Prevent further navigation
        }
      } 
      // Specific check for reps and SDRs only accessing their own dashboards
      else if (role === 'rep' && to.name === 'employee-dashboard' && to.params.name !== user.name) {
        next(`/team/employee-dashboard/${user.name}`) // Redirect to their own dashboard
      } else if (role === 'sdr' && to.name === 'sdr-dashboard' && to.params.name !== user.name) {
        next(`/team/sdr-dashboard/${user.name}`) // Redirect to their own dashboard
      } 
      // All checks passed, allow access
      else {
        next()
      }
    }
  } else {
    // Route does not require authentication, grant access
    next()
  }

})

export default router
