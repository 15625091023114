<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import Dashboard1 from '../components/dashboards/CompanyDash.vue';
import Dashboard2 from '../components/dashboards/ClientDash.vue';
import Dashboard3 from '../components/dashboards/ClientOnboard.vue';
import Dashboard4 from '../components/dashboards/DbaseMiro.vue';
import Dashboard5 from '../components/dashboards/ClientData.vue';
import Dashboard6 from '../components/dashboards/ClientSDRData.vue';

const router = useRouter()
const route = useRoute()

const dashboards = ref({
  'company-dash': Dashboard1,
  'client-dash': Dashboard2,
  'client-onboard': Dashboard3,
  'dbase-miro': Dashboard4,
  'client-rep-data': Dashboard5,
  'client-sdr-data': Dashboard6
})

onMounted(async () => {
  await router.isReady()    
})

const currentDashboardComponent = computed(() => {
  let dashboardName = route.query.data
  if (!dashboardName) dashboardName = 'company-dash'
  return dashboards.value[dashboardName] || null
})

</script>

<template>
  <div class="page-width">
    <component :is="currentDashboardComponent"></component>
  </div>
</template>

<style scoped>
</style>
