<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'

const router = useRouter()
const store = useStore()

const userRole = ref(store.state.role)
const isDropdownOpen = ref(false)

onMounted(() => {
  userRole.value = store.state.role
  document.addEventListener('click', handleClick)
})

// Watches for any changes in the user role
watch(() => store.state.role, (newRole) => {
  userRole.value = newRole
}, { immediate: true })

let closeDropdown = false
const handleClick = (event) => { 
  if (closeDropdown) {
    isDropdownOpen.value = false
    closeDropdown = false
  }
  if (isDropdownOpen.value) {
    closeDropdown = true
  }
}

// Settings dashboard menu options
const items = ref([
  {
    label: 'Data Dashboards',
    items: [
      {
        label: 'Company Dashboards',
        icon: 'pi pi-chart-bar',
        command: () => {
          router.push({ path: '/', query: { data: 'company-dash' } })
        }
      },
      {
        label: 'Client Dashboards',
        icon: 'pi pi-chart-line',
        command: () => {
          router.push({ path: '/', query: { data: 'client-dash' } })
        }
      },
      {
        label: 'Rep + Client Data',
        icon: 'pi pi-table',
        command: () => {
          router.push({ path: '/', query: { data: 'client-rep-data' } })
        }
      },
      {
        label: 'SDR + Client Setter Data',
        icon: 'pi pi-table',
        command: () => {
          router.push({ path: '/', query: { data: 'client-sdr-data' } })
        }
      }
    ]
  },
  {
    label: 'Process Maps',
    items: [
      {
        label: 'Client Onboarding',
        icon: 'pi pi-list-check',
        command: () => {
          router.push({ path: '/', query: { data: 'client-onboard' } })
        }
      },
      {
        label: 'Database Design',
        icon: 'pi pi-database',
        command: () => {
          router.push({ path: '/', query: { data: 'dbase-miro' } })
        }
      }
    ]
  }
])

// Show / hide dashboard menu
const toggle = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

// Sign user out
const logout = async () => {
  try {
    await axios.get(`${process.env.VUE_APP_API_AUTH_URL}/logout`)
    store.dispatch('logout')
    router.push({ name: 'Login' })
  } catch (error) {
    console.error('Error logging out:', error)
  }
}

</script>

<template>
  <header v-if="userRole != null">
    <nav class="page-width">
      <div class="branding">
        <img src="@/assets/images/logo_black.svg" alt="Logo">
      </div>
      <ul class="navigation">
        <li v-if="userRole === 'admin'">
          <button id="dash" class="link icon-group" @click="toggle"> <i class="pi pi-chart-bar"></i><span class="link-text">Dashboards</span></button> 
          <Menu id="dashMenu" :model="items" v-if="isDropdownOpen" />
        </li>
        <li v-if="userRole === 'admin' || userRole === 'manager'">
          <router-link class="link icon-group" :to="{name: 'team'}"><i class="pi pi-users"></i><span class="link-text">Team</span></router-link>
        </li>
        <li v-if="userRole === 'admin' || userRole === 'manager'">
          <router-link class="link icon-group" :to="{name: 'accounts'}"><i class="pi pi-address-book"></i><span class="link-text">Accounts</span></router-link>
        </li>
        <li v-if="userRole !== 'admin' & userRole !== 'manager'">
          <router-link class="link icon-group" :to="{name: 'home'}"><i class="pi pi-home"></i><span class="link-text">Dashboard</span></router-link>
        </li>
        <li>
          <router-link class="link icon-group" :to="{name: 'resources'}"><i class="pi pi-info-circle"></i><span class="link-text">Resources</span></router-link>
        </li>
        <li class="logout">
          <button class="link icon-group" @click="logout"> <i class="pi pi-sign-out"></i></button>
        </li>
      </ul>
    </nav>
  </header>
</template>

<style scoped>

header {
  display: flex;
  align-items: center;
  height: 5rem;
  width: 100%;
  z-index: 99;
  transition: 0.5s ease all;
  color: var(--txt-color);
  background-color: #fff;
  box-shadow: 0 -2px 15px #0003;
}
nav {
  display: flex;
  flex-direction: row;
  position: relative;
  transition: 0.5s ease all;
}
nav ul {
  display: flex;
  flex-direction: row;
}
nav .link, nav ul, nav button {
  font-weight: 500;
  color: var(--txt-color);
  list-style: none;
  text-decoration: none;
}
nav li {
  padding: 1rem;
}
nav a, nav button {
  text-transform: uppercase;
}
nav .link {
  padding: 0.4rem 0;
  border-bottom: 1px solid transparent;
  transition: 0.5s ease all;
}
nav .link:hover {
  border-color: var(--primary-color); 
}
nav .branding {
  display: flex;
  align-items: center;
}
nav .branding img {
  width: 19rem;
  transition: 0.5s ease all;
}
nav .navigation {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}
button {
  border: none;
  background-color: unset;
  cursor: pointer;
  font: var(--text-font);
}

.icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 1rem;
  height: 100%;
}
.icon i {
  cursor: pointer;
  font-size: 2rem;
  transition: 0.8s ease all;
}
.icon-active {
  transform: rotate(180deg);
}
.icon-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media only screen and (max-width: 850px) {
  .link-text {
    display: none;
  }
}

</style>

<style>
  #dashMenu {
    position: absolute;
    transform-origin: center top;
    margin-top: calc(var(--p-anchor-gutter));
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    z-index: 99;
  }
</style>