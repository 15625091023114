<script setup>
import axios from 'axios'
import { ref, onMounted, defineProps, defineEmits } from "vue"

const emit = defineEmits(['showModal', 'errorModal', 'successModal'])

const date = ref()


const sendReport = async () => {
  
  try {
  
    // Check if date was entered
    if (!date.value || !date.value[1]) 
      throw new Error('Please select a start and end date to send the report')
    // Confirm with user
    if (!confirm("Are you sure you would like to send the daily report to Slack?")) 
      return
  
    // Format date to SQL format
    let startDate = convertToSQLDate(date.value[0])
    let endDate = convertToSQLDate(date.value[1])

    const res = await axios.post(`${process.env.VUE_APP_API_URL}/send-daily-report?start=${startDate}&end=${endDate}`)

    emit('successModal', 'Successfully sent daily report to Slack!')
    emit('showModal')
    
  } 
  catch (error) {
    console.error(error.stack)
    emit('errorModal', "Could not send report to slack.. \n" + error.message)
    return
  }
}

/**
 * Helper.. Converts js date to sql readable date
 * @param jsDate 
 */
function convertToSQLDate(jsDate, add24hrs) {
    const padZero = (num) => (num < 10 ? '0' : '') + num;

    // Add 4 hours to adjust for timezone issue
    jsDate.setHours(jsDate.getHours() + 5);

    const year = jsDate.getFullYear();
    const month = padZero(jsDate.getMonth() + 1); // Months are zero-based
    const day = padZero(jsDate.getDate());
    const hours = padZero(jsDate.getHours());
    const minutes = padZero(jsDate.getMinutes());
    const seconds = padZero(jsDate.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

</script>

<template>
<Dialog modal header="Send Daily Report" :style="{ width: '25rem' }">
  <p>Choose a date range: </p>
  <Calendar v-model="date" selectionMode="range" :manualInput="false" inline class="calendar" />
  <p class="note">Note: You must select two dates. The second date is non inclusive (i.e. The report is sent from Date 1 up until but <span class="bold">not</span> including Date 2)</p>
  <Button @click="sendReport" class="send-btn">Send to Slack</Button>
</Dialog>
</template>

<style scoped>

.calendar {
  display: block;
}
.note {
  font-size: 0.8rem;
}
.bold {
  font-weight: 900;
}
.send-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  justify-content: center;
}

</style>